//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import logoWhatsapp from '$resources/images/whatsapp.png';

export default {
  data: () => ({
    acceptCommercialComms: false,
    alertCommercialComms: false,
    message: '',
    phone: '',
    showPhoneInput: false,
    logoWhatsapp
  }),
  updated() {
    this.$emit('content-update');
  },
  methods: {
    handleSendMessage() {
      setTimeout(() => {
        this.alertCommercialComms = false;
      }, 1000);
    },
    handleSendPhone(event) {
      this.$emit('send-phone', { phone: this.phone });
      event.preventDefault();

      setTimeout(() => {
        this.alertCommercialComms = false;
      }, 1000);
    }
  }
};
